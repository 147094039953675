import React from "react";
import Link from "next/link";
import styles from "./HomePage.module.scss";
import useAxios from "axios-hooks";
import { WEB_API } from "../../constants";

const TopCategories = () => {
  const [{ data, loading, error }, refetch] = useAxios(
    WEB_API + "/categories/featured/list"
  );

  // console.log("Category Data", data);

  return (
    <div className="categories-area ptb-100">
      <div className={styles["container"]}>
        <div className={styles["section-title"]}>
          <span className={styles["sub-title"]}>Categories</span>
          <h2>Top Categories</h2>
          {/* <p>We deliver the best and results are the validations.</p> */}
        </div>

        <div className="row">
          {data
            ? data.rows.map((category, i) => (
                <div key={i} className="col-lg-3 col-sm-6 col-md-6">
                  <div className={styles["single-categories-box"]}>
                    <img
                      style={{ width: "100%" }}
                      src={category.img}
                      className="h-48"
                      alt="image"
                    />

                    <div className={styles["content"]}>
                      <h3>{category.name}</h3>
                    </div>

                    <Link href={`/categories/${category.slug}`}>
                      <a className={styles["link-btn"]}></a>
                    </Link>
                  </div>
                </div>
              ))
            : "Loading"}

          <div className="col-lg-12 col-sm-12 col-md-12">
            <div className={styles["categories-btn-box"]}>
              <Link href="/categories">
                <a className={styles["default-btn"]}>
                  <i className="flaticon-user"></i> View All Categories{" "}
                  <span></span>
                </a>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopCategories;
