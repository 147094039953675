import React from "react";
import Link from "next/link";
import styles from "./HomePage.module.scss";
import { BiBrain, BiCheckShield } from "react-icons/bi";
import { HiOutlineDesktopComputer } from "react-icons/hi";

const Features = () => {
  return (
    <div className={`${styles["features-area"]} pt-100 pb-70`}>
      <div className={styles["container"]}>
        <div className={styles["section-title"]}>
          <span className={styles["sub-title"]}>
            Affordable preparation For Everyone
          </span>
          <h2>Advantages with Toppersnotes Courses</h2>
          <p>With Toppersnotes get one step ahead of your competition by-</p>
        </div>

        <div className="row">
          <div className="col-lg-4 col-sm-6 col-md-6">
            <div className={`${styles["single-features-box"]} without-padding`}>
              <div className={`flex justify-center ${styles["icon"]}`}>
                <BiBrain />
              </div>
              <h3>Correct Strategy</h3>
              <p>
                From the toppers and faculty you will get the right strategy to
                study effortlessly and also with Short tricks and mnemonics to
                remember easily for eternity.
              </p>

              <Link href="/profile-authentication">
                <a className={styles["link-btn"]}>Start Now!</a>
              </Link>
            </div>
          </div>

          <div className="col-lg-4 col-sm-6 col-md-6">
            <div className={`${styles["single-features-box"]} without-padding`}>
              <div className={`flex justify-center ${styles["icon"]}`}>
                <HiOutlineDesktopComputer />
              </div>
              <h3>Subject exam relevancy </h3>
              <p>
                Specific content for each subject only up to what is relevant
                for your exam saves you time and is handy for Quick Revision
              </p>

              <Link href="/profile-authentication">
                <a className={styles["link-btn"]}>Start Now!</a>
              </Link>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
            <div className={`${styles["single-features-box"]} without-padding`}>
              <div className={`flex justify-center ${styles["icon"]}`}>
                <BiCheckShield />
              </div>
              <h3>Topic-wise practice questions</h3>
              <p>
                After completing a topic you can always self examine through our
                Learning app. This helps in keeping a track of your overall
                studies and targets.
              </p>

              <Link href="/profile-authentication">
                <a className={styles["link-btn"]}>Start Now!</a>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Features;
