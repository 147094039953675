import React from "react";
import Link from "next/link";
import API from "../../axios-setup/web-api";
import styles from "./HomePage.module.scss";

const CoursesArea = () => {
  const [featuredCourses, setFeaturedCourses] = React.useState(null);

  React.useEffect(() => {
    const getAllFeaturedCourses = async () => {
      const res = await API.get("/products/featured");

      setFeaturedCourses(res.data.data.rows);
    };
    getAllFeaturedCourses();
  }, []);

  return (
    <div className={`${styles["courses-area"]} ptb-100 bg-f5f7fa`}>
      <div className={styles["container"]}>
        <div className={styles["section-title"]}>
          <span className={styles["sub-title"]}>
            Are you ready to be the next topper?
          </span>
          <h2>India's Best Solution for relevant Study</h2>
          <p>
            India’s most preferred study material is now available for you.
            Click to know more
          </p>
        </div>

        <div className="row">
          {featuredCourses &&
            featuredCourses.slice(0, 8).map((course, i) => (
              <div key={i} className="w-1/2">
                <a href={`/product/${course.product_link}`}>
                  <div className={styles["single-courses-item"]}>
                    <div className="row align-items-center">
                      <div className="col-lg-4 col-md-4">
                        <div className={styles["courses-image"]}>
                          <img
                            src={course.img1}
                            alt="image"
                            className=" h-56 object-cover"
                          />
                          <span className={styles["link-btn"]}></span>
                        </div>
                      </div>

                      <div className="col-lg-8 col-md-8">
                        <div className={styles["courses-content"]}>
                          <span className={styles["price"]}>
                            Rs
                            {course.offer_price
                              ? course.offer_price
                              : course.price}
                          </span>
                          <h3 style={{ lineHeight: "1rem" }}>
                            <Link href={`/product/${course.product_link}`}>
                              <span className="text text-xs md:text-lg">
                                {course.name}
                              </span>
                            </Link>
                          </h3>
                          <p>
                            {" "}
                            <i className="flaticon-agenda"></i>{" "}
                            {course.no_of_books} Books
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            ))}
        </div>
      </div>

      <div className={styles["shape16"]}>
        <img src="/images/shape15.png" alt="image" />
      </div>
    </div>
  );
};

export default CoursesArea;
