import React from "react";
import Link from "next/link";
import styles from "./HomePage.module.scss";
import * as fbq from "../../lib/fpixel";
import { useRouter } from "next/router";
import { BiSearchAlt, BiBrain, BiWorld } from "react-icons/bi";
import { BsPeople } from "react-icons/bs";

const popularTags = ["UPSC", "NEET", "REET", "RAS", "BPSC", "UPPCS"];

const MainBanner = ({ data }) => {
  const [event, setEvent] = React.useState(false);
  const [searchKeyword, setSearchKeyword] = React.useState("");
  const router = useRouter();

  const handleSearch = (e) => {
    e.preventDefault();

    if (!event) {
      fbq.event("Search");
      setEvent(true);
    }
    router.push("/search?keyword=" + searchKeyword);
  };
  return (
    <div className={styles["banner-wrapper-area"]}>
      <div className={styles["container"]}>
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12">
            <div className={`${styles["banner-wrapper-content"]} text-3xl`}>
              <h1>Unleash The Topper In You</h1>
              <p>
                Save your most valuable Time, and Money with expert-curated
                content and personalized learning.
              </p>

              <form onSubmit={handleSearch}>
                <label>
                  <BiSearchAlt />
                  {/* <i className="flaticon-search"></i> */}
                </label>
                <input
                  type="text"
                  value={searchKeyword}
                  className={styles["input-search"]}
                  onChange={(e) => setSearchKeyword(e.target.value)}
                  placeholder="Search for courses"
                />
                {/* <button className="form-btn" type="submit"></button> */}
                <button className={styles["form-btn"]} type="submit">
                  Search Now
                </button>
              </form>

              {data && (
                <div>
                  <ul className={styles["popular-search-list"]}>
                    <li>
                      <span>Select Exam Category:</span>
                    </li>

                    {data
                      .filter(
                        (cat) => cat.type == "exam" && cat.featured == "1"
                      )
                      .map((cat, i) => (
                        <li key={i}>
                          <Link href={`/categories/${cat.slug}`}>
                            <a>{cat.name}</a>
                          </Link>
                        </li>
                      ))}
                  </ul>
                  <ul className={styles["popular-search-list"]}>
                    <li>
                      <span>State Exams:</span>
                    </li>

                    {data
                      .filter(
                        (cat) => cat.type == "state" && cat.featured == "1"
                      )
                      .map((cat, i) => (
                        <li key={i}>
                          <Link href={`/categories/${cat.slug}`}>
                            <a>{cat.name}</a>
                          </Link>
                        </li>
                      ))}
                  </ul>
                </div>
              )}
            </div>
          </div>

          <div className="col-lg-6 col-md-12">
            <div className={styles["banner-wrapper-image"]}>
              {/* <img src="/images/banner-img2.png" alt="image" /> */}
              <img
                src={
                  "https://toppersnotes-web.s3.ap-south-1.amazonaws.com/Gallery/Products/ezgif-1-dee9d50746.webp"
                }
                alt="image"
              />
              <div className={styles["banner-shape8"]}>
                <img src="/images/banner-shape8.png" alt="image" />
              </div>
              <div className={styles["banner-shape9"]}>
                <img src="/images/banner-shape9.png" alt="image" />
              </div>
              <div className={styles["banner-shape10"]}>
                <img src="/images/banner-shape10.png" alt="image" />
              </div>
            </div>
          </div>
        </div>

        <div className={styles["banner-inner-area"]}>
          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className={styles["single-banner-box"]}>
                <div className={`flex justify-center ${styles["icon"]}`}>
                  <BiBrain />
                  {/* <i className="flaticon-brain-process"></i> */}
                </div>
                <h3>Expert Curated Content</h3>
                <p>
                  Our Subject Matter Experts create the best and most refined
                  content in an efficient and correct manner of study.
                </p>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className={styles["single-banner-box"]}>
                <div className={`flex justify-center ${styles["icon"]}`}>
                  <BsPeople />
                  {/* <i className="flaticon-people"></i> */}
                </div>
                <h3>Shipping Across India</h3>
                <p>
                  We are operating for all the students across India. Order and
                  get delivery of notes at your Doorstep.
                </p>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className={styles["single-banner-box"]}>
                <div className={`flex justify-center ${styles["icon"]}`}>
                  <BiWorld />
                  {/* <i className="flaticon-world"></i> */}
                </div>
                <h3>100+ Exams</h3>
                <p>
                  Get Expert Curated Content with the right strategy to clear
                  the exam for all India's major national and state level exams.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={styles["divider"]}></div>
    </div>
  );
};

export default MainBanner;
