import React from "react";
import styles from "../OnlineTrainingSchool/HomePage.module.scss";

const FunFactsTwo = () => {
  return (
    <div className={`${styles[""]} bg-fffaf3`}>
      <div className={styles["container"]}>
        <div className="row">
          <div className="col-lg-2 col-md-2 col-sm-6">
            <div className={styles["single-funfacts-item"]}>
              <h3>16+</h3>
              <p>States</p>
            </div>
          </div>

          <div className="col-lg-2 col-md-2 col-sm-6">
            <div className={styles["single-funfacts-item"]}>
              <h3>1.2 Lac+</h3>
              <p>Students trust ToppersNotes</p>
            </div>
          </div>

          <div className="col-lg-2 col-md-2 col-sm-6">
            <div className={styles["single-funfacts-item"]}>
              <h3>90%</h3>
              <p>Questions guarantee</p>
            </div>
          </div>

          <div className="col-lg-2 col-md-2 col-sm-6">
            <div className={styles["single-funfacts-item"]}>
              <h3>4+</h3>
              <p>Languages</p>
            </div>
          </div>
          <div className="col-lg-2 col-md-2 col-sm-6">
            <div className={styles["single-funfacts-item"]}>
              <h3>5,236+</h3>
              <p>Selections</p>
            </div>
          </div>
          <div className="col-lg-2 col-md-2 col-sm-6">
            <div className={styles["single-funfacts-item"]}>
              <h3>96%</h3>
              <p>Cost benefit</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FunFactsTwo;
