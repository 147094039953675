import axios from "axios";

// const URL = "https://dev-web-api.toppersnotes.com";
import { WEB_API } from "../constants";

const URL = WEB_API;

const API = axios.create({
  baseURL: URL,
  headers: {
    "Content-Type": "application/json",
  },
});

API.interceptors.response.use(
  (res) => res,
  (err) => {
    return Promise.reject(err);
  }
);

export default API;
