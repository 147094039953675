import React, { useEffect, useState } from "react";
import Header from "../components/_App/Navbar1";
import MainBanner from "../components/OnlineTrainingSchool/MainBanner";
import CoursesArea from "../components/OnlineTrainingSchool/CoursesArea";
import Features from "../components/OnlineTrainingSchool/Features";
import AboutArea from "../components/OnlineTrainingSchool/AboutArea";
// import FeedbackSlider from "../components/OnlineTrainingSchool/FeedbackSlider";
import TopCategories from "../components/OnlineTrainingSchool/TopCategories";
import FunFactsTwo from "../components/Common/FunFactsTwo";
import TestimonialSlider from "../components/products/testimonial-slider";
// import CourseAdvisor from "../components/OnlineTrainingSchool/CourseAdvisor";
// import PremiumAccess from "../components/OnlineTrainingSchool/PremiumAccess";
// import LatestNewsTwo from "../components/Common/LatestNewsTwo";
// import SubscribeForm from "../components/Common/SubscribeForm";
// import Partner from "../components/Common/Partner";
import Footer from "../components/_App/Footer";
import { useRouter } from "next/router";
// import axios from "axios";
import orderAPI from "../axios-setup/order-service";
import Head from "next/head";
import API from "../axios-setup/web-api";
import Banner from "../components/_App/Banner";

const Index = ({ dataNav }) => {
  const [testimonials, setTestimonials] = React.useState(null);

  React.useEffect(() => {
    //Get All Featured Testimonials
    const getAllTestimonials = async () => {
      const res = await API.get("/testimonials/featured");
      setTestimonials(res.data.data);
    };

    getAllTestimonials();
  }, []);
  const [tabUrl, setTabUrl] = useState(null);
  const [title, setTitle] = useState("");
  const [images, setImages] = useState(null);
  useEffect(() => {
    setTabUrl(window.location.href);
    setTitle(document.title);
    setImages(document.images[0].src);
  }, []);

  useEffect(() => {
    // let r = (Math.random() + 1).toString(36).substring(7);
    // console.log("title is:", document.title);
    // console.log("images is:", document.images[0].src);
  });

  const router = useRouter();

  const logCtr = async () => {
    const hash_key = router.query.referral_code;
    if (typeof window !== "undefined") {
      try {
        await orderAPI.post("/url/log", {
          hash_key,
          browser_id: localStorage.getItem("FPJS").replace(/"/g, ""),
          landing_url: window.location.href,
        });
      } catch (error) {
        console.log(error);
      }
    }
  };
  useEffect(() => {
    if (
      router.query.referral_code &&
      router.query.referral_code !== "" &&
      router.asPath
    )
      logCtr();
  }, [router]);
  return (
    <React.Fragment>
      <Banner />
      <Header data={dataNav} />
      <MainBanner data={dataNav} />
      <div>
        <Head>
          <link
            href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css"
            rel="stylesheet"
            integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC"
            crossorigin="anonymous"
          ></link>
          {/* <title>{title}</title> */}
          <meta
            name="viewport"
            content="initial-scale=1.0, width=device-width"
          />
          <meta property="og:title" content={title} />
          <meta
            name="description"
            content="Save your most valuable Time, and Money with expert-curated content and personalized learning."
          />
          <meta
            property="og:description"
            content="Save your most valuable Time, and Money with expert-curated content and personalized learning."
          />
          <meta property="og:type" content="website" />
          <meta property="og:url" content={tabUrl} />
          <meta property="og:image" content={images} />
          <link
            rel="canonical"
            // property="og:title"
            // content={title}
            // key="title"
            href={tabUrl}
          ></link>
        </Head>
      </div>
      <CoursesArea />
      <Features />
      <AboutArea />
      {/* <FeedbackSlider /> */}
      {testimonials && (
        <TestimonialSlider showHeading={false} testimonials={testimonials} />
      )}
      <TopCategories />
      <FunFactsTwo />
      {/* <CourseAdvisor /> */}
      {/* <PremiumAccess /> */}
      {/* <LatestNewsTwo /> */}
      {/* <SubscribeForm /> */}
      {/* <Partner /> */}
      <Footer />
    </React.Fragment>
  );
};

export default Index;
